import { render, staticRenderFns } from "./subactivity.vue?vue&type=template&id=5228dd26"
import script from "./subactivity.vue?vue&type=script&lang=js"
export * from "./subactivity.vue?vue&type=script&lang=js"
import style0 from "./subactivity.vue?vue&type=style&index=0&id=5228dd26&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5228dd26')) {
      api.createRecord('5228dd26', component.options)
    } else {
      api.reload('5228dd26', component.options)
    }
    module.hot.accept("./subactivity.vue?vue&type=template&id=5228dd26", function () {
      api.rerender('5228dd26', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/confApps/confAvec/subactivity.vue"
export default component.exports