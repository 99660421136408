import * as statusMapper from "@/service/error_request_mapper.js";


export class ConfAppAvecSubactivityService {
  constructor(api) {
    this.api = api;
  }

  async getAll() {
    return this.api
      .getAll()
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.convert(error);
      });
  }

  /** Récupération d'un objet par son id */
  async getById(entityId) {
    return this.api
      .getById(entityId)
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.add404(entityId);
        converter.convert(error);
      });
  }

  async create(entity) {
    return this.api
      .createConf(entity)
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.add404(entity.establishmentId);
        converter.add409AlreadyExists(entity.establishmentId);
        converter.convert(error);
    });
  }

  /** Met à jour un objet */
  async update(entity) {
    return this.api
      .updateConf(entity)
      .catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404(entity.establishmentId);
      converter.convert(error);
    });
  }

  /** Suppression d'un objet  */
  async delete(entityId) {
    return this.api
      .deleteConf(entityId)
      .catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add409Integrity(entityId);
      converter.convert(error);
    });
  }
}